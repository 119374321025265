






import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

import { Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { GeometryObject } from 'geojson';
import { Vector } from 'ol/source';

export default Vue.extend({
  props: {
    geojson: {
      type: Object as () => GeometryObject,
    },
    data: {
      type: Object as () => any,
    },
  },

  data(): {
    id: string;
    feature?: Feature;
  } {
    return {
      id: uuidv4(),
      feature: undefined,
    };
  },

  computed: {
    map(): Map | undefined {
      return (this as any)._getMap();
    },
    layer(): VectorLayer | undefined {
      return (this as any)._getLayer();
    },
    source(): Vector | undefined {
      return (this as any)._getSource();
    },
  },

  inject: {
    _getMap: {
      default: undefined,
    },
    _getLayer: {
      default: undefined,
    },
    _getSource: {
      default: undefined,
    },
  },

  watch: {
    feature: {
      immediate: false,
      handler() {
        if (this.source && this.feature) {
          this.source.addFeature(this.feature);
        }
      },
    },

    geojson: {
      immediate: true,
      handler() {
        if (this.source && this.feature) {
          this.source.removeFeature(this.feature);
        }

        this.feature = new Feature({
          geometry: new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }).readGeometry(this.geojson),
          id: this.id,
          data: {
            ...this.data,
          },
        });
      },
    },
  },

  destroyed() {
    if (this.source && this.feature) {
      this.source.removeFeature(this.feature);
    }
  },
});
